<template>
  <div class="d-flex">
    <!-- 搜尋框 -->
    <v-text-field
      v-model="search"
      density="compact"
      label="Search"
      prepend-inner-icon="fa-solid fa-magnifying-glass"
      variant="solo-filled"
      flat
      hide-details
      single-line
      class="searchInput me-2"
      :disabled="disabled"
      @change="getSearch"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'searchFn'])

const disabled = ref(props.disabled)

const search = ref(props.modelValue)

watchEffect(() => {
  search.value = props.modelValue
  disabled.value = props.disabled
})

// 發送變更事件給父層
watch(search, (newVal) => {
  emit('update:modelValue', newVal)
})

const getSearch = () => {
  emit('searchFn', search.value)
}
</script>

<style lang="scss" scoped>
.searchInput {
  width: 300px;
}
:deep(.v-field__prepend-inner > .v-icon) {
  opacity: 1;
  font-size: 14px;
  margin-right: 5px;
}
</style>
