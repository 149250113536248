import { useRuntimeConfig } from '#app'

const { useCustomFetch } = useFetchBase()

const base = '/api/message'
const modules = '/channels'
const path = base + modules

// request(REQ) Api  --------------------------------
const getChannelsREQ = path + '/list' // 獲取渠道列表
const getSimpleChannelsREQ = path + '/list/simple' // 獲取渠道簡易列表
const addThirdChannelVerifyREQ = path + '/add/verifyRepeat' // 新增第三方渠道驗證重複
const addChannelREQ = path + '/add' // 新增渠道
const editThirdChannelVerifyREQ = path + '/update/verifyRepeat' // 修改第三方渠道驗證重複
const editChannelREQ = path + '/update' // 修改渠道
const delChannelREQ = path + '/del' // 刪除渠道
const searchChannelREQ = path + '/list/q' // 搜尋渠道

const getChannelsDetailsREQ = path + '/url/details' // 獲取渠道細節 根據【渠道Url】

// request(REQ) Function  --------------------------------

/**
 * 獲取渠道列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getChannelsREQFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getChannelsREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取渠道簡易列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getSimpleChannelsREQFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getSimpleChannelsREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}


/**
 * 新增第三方渠道驗證重複
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const addThirdChannelVerifyREQFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addThirdChannelVerifyREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 新增渠道
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const addChannelREQFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addChannelREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}


/**
 * 修改第三方渠道驗證重複
 * @param {Object} params 查詢參數
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const editThirdChannelVerifyREQFn = (params, formData, callback) => {
  //params參數為channelId，需傳遞
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editThirdChannelVerifyREQ, params), {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}


/**
 * 修改渠道
 * @param {Object} params 查詢參數
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const editChannelREQFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editChannelREQ, params), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除渠道
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const delChannelREQFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(delChannelREQ, params), {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 搜尋渠道
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const searchChannelFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(searchChannelREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取渠道細節
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getChannelsDetailsFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getChannelsDetailsREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}
