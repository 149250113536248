<template>
  <div class="h-100">
    <SetupTitle :name="t('setup.trustedDomain.title')" />

    <div class="setupMain">
      <div class="tableContent">
        <v-row no-gutters class="h100">
          <v-col cols="12">
            <div class="switchBtn">
              <div>
                <div class="subTitle font-weight-bold">
                  {{ t('setup.trustedDomain.SubTitle') }}
                </div>
              </div>
            </div>
            <div class="mt-10 mb-10">
              <div id="toolBar">
                <SetupSearchBar
                  :is-show-search-input="true"
                  :is-show-multiple-select="false"
                  @searchSubmitFn="searchTrustedDomain"
                />
                <CommonButtonAdd
                  :name="t('setup.trustedDomain.addBtn')"
                  @click="showTrustedDomainDlg"
                />
              </div>
              <div class="tableStyle">
                <LazySetupTrustedDomainTable
                  :progress-loading="tableProgressLoading"
                  :table-data="trustedDomainList"
                  @editTrustedDomain="editTrustedDomainDlg"
                  @deleteTrustedDomain="deleteTrustedDomainDlg"
                  @refreshTrustedDomain="getTrustedDomainList"
                />
              </div>

              <!-- 新增標籤 -->
              <LazySetupTrustedDomainAddEditDlg
                :is-show="dlgTrustedDomainVisible"
                :dialog-info="trustedDomainInfo"
                @closeDlg="closeTrustedDomainDlg"
              />

              <!-- 刪除標籤 -->
              <LazyCommonDeleteDlg
                v-model:dlgVisible="dlgVisible"
                :data="deleteDomainInfo"
                :sub-title-first="t('setup.trustedDomain.delDlgTitleFirst')"
                :sub-title-second="t('setup.trustedDomain.delDlgTitleSecond')"
                :sub-title-object-name="deleteDomainName"
                :content="t('setup.trustedDomain.delDlgText')"
                @deleteFn="deleteTrustedDomain"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getTenantFn } from '../../../api/auth/tenant'
import { getTrustedDomainListFn, deleteTrustedDomainFn } from '../../../api/auth/trustedDomain'
const { t } = useI18n()

// get api loading
const progressLoading = ref(false)
const tableProgressLoading = ref(true)

onBeforeMount(async () => {
  progressLoading.value = true
  await getTrustedDomainList()
})

// loading
const loading = ref(false)

// 信任網站列表
const trustedDomainList = ref([])

// 當前頁碼
const currentPage = ref(1)

// 搜索輸入框
const searchInput = ref('')

// 獲取信任網站列表
const getTrustedDomainList = async (page, search) => {
  currentPage.value = page || currentPage.value
  searchInput.value = search !== undefined ? search : searchInput.value

  const params = {
    pageNum: currentPage.value,
    pageSize: 20,
    domain: searchInput.value || ''
  }

  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      tableProgressLoading.value = false
      trustedDomainList.value = res.data
    }
  }
  await getTrustedDomainListFn(params, onRes)
}

// 搜索信任網站
const searchTrustedDomain = (search) => {
  getTrustedDomainList(1, search.searchInput)
}

// 信任網站對話框
const dlgTrustedDomainVisible = ref(false)
const trustedDomainInfo = reactive({
  dlg: {
    type: 'add'
  },
  data: {
    id: '',
    domain: '',
    remark: ''
  }
})

// 開啟信任網站對話框 - 新增
const showTrustedDomainDlg = () => {
  trustedDomainInfo.dlg.type = 'add'
  trustedDomainInfo.data = {
    id: '',
    domain: '',
    remark: ''
  }

  dlgTrustedDomainVisible.value = true
}

// 開啟信任網站對話框 - 編輯
const editTrustedDomainDlg = (item) => {
  trustedDomainInfo.dlg.type = 'edit'
  trustedDomainInfo.data = item
  dlgTrustedDomainVisible.value = true
}

// 關閉信任網站對話框
const closeTrustedDomainDlg = (value) => {
  dlgTrustedDomainVisible.value = false
  if (value) {
    getTrustedDomainList(currentPage.value)
  }
}

// 刪除信任網站
const dlgVisible = ref(false)
const deleteDomainInfo = ref()
const deleteDomainName = ref('')

// 刪除信任網站對話框
const deleteTrustedDomainDlg = (item) => {
  deleteDomainInfo.value = item
  deleteDomainName.value = item.domain
  dlgVisible.value = true
}

// 刪除信任網站
const deleteTrustedDomain = (item) => {
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      ElMessage.success({ message: t('setup.trustedDomain.delete_sucMsg') }) // 刪除成功
      getTrustedDomainList(currentPage.value)
    }
  }
  const params = {
    trustedDomainId: item.id
  }
  deleteTrustedDomainFn(params, onRes)
  dlgVisible.value = false
}
</script>

<style lang="scss" scoped>
  @import "assets/styles/pages/setup.scss";
</style>
