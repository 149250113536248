<template>
  <!-- 下拉選單(多選) -->
  <div id="multipleSelect">
    <span class="multipleLabel">{{ label }}</span>
    <v-select
      v-model="select"
      :items="multipleSelect"
      :item-title="title"
      :item-value="itemValue"
      multiple
      hide-details
      variant="underlined"
      @update:model-value="getSelect"
    >
      <!-- 超過5個則顯示數字 -->
      <template #selection="{ item, index }">
        <v-chip v-if="index < 5">
          <span>{{ item.title }} </span>
        </v-chip>
        <span
          v-if="index === 5"
          class="text-grey text-caption align-self-center"
        >
          (+{{ select.length - 5 }} others)
        </span>
      </template>
    </v-select>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  label: {
    type: String,
    default: ''
  },
  multipleSelect: {
    type: Array,
    default: () => []
  },
  title: {
    type: String,
    default: ''
  },
  itemValue: {
    type: String,
    default: ''
  }
})

const select = ref(props.modelValue) // 選到的值

watchEffect(() => {
  select.value = props.modelValue
})

const emit = defineEmits(['selectFn'])
const getSelect = (newValue) => {
  // TODO 這邊需要改成傳陣列(newValue)，目前影響太多先不改
  emit('selectFn', select.value)
}
</script>

<style lang="scss" scoped>
  #multipleSelect {
    display: flex; align-items: center;
    background: #f6f6f6;
    border-radius:4px;
    padding:0 14px;

    .multipleLabel {
      font-size: 16px;
      display: inline-block;
    }
  }
  :deep(.v-select--multiple) {
    min-width: 180px;

    .v-field__input {
      padding-top: 11px;
      padding-bottom: 11px;
    }
    .v-field__append-inner {
      padding-top: 11px;
    }
    .v-field__outline::before {
      border-style: none;
    }
    .v-chip {
      height: 26px;
    }
    .text-caption {
      margin-left: 10px;
    }
  }
</style>
