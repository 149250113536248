
// 黑名單控制器
import { useRuntimeConfig } from '#app'

const { useCustomFetch } = useFetchBase()

const base = '/api/admin'
const modules = '/blackLists'
const path = base + modules

// request(REQ) Api  --------------------------------
const searchBlacklistREQ = path + '/list/q' // 搜索黑名單
const addBlacklistREQ = path + '/add' // 添加黑名單
const editBlackListREQ = path + '/update' // 更新黑名單
const deleteBlackListREQ = path + '/del' // 刪除黑名單

// request(REQ) Function  --------------------------------

/**
 * 獲取黑名單列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 資料
 */
export const getBlackListsFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(searchBlacklistREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 添加黑名單
 * @param {Object} formData 表單提交
 * @param {Object} callback 回掉函數
 * @returns
 */
export const addBlackListFn = (formData, callback) => { // 添加
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addBlacklistREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 更新黑名單
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單資料
 * @param {Object} callback  回掉函數
 * @returns
 */
export const editBlackListFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editBlackListREQ, params), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除黑名單
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const deleteBlackListFn = (params, callback) => { // 刪除
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(deleteBlackListREQ, params), {
    method: 'DELETE',
    onResponse: callback
  })
}
