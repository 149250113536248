import { useRuntimeConfig } from '#app'

const { useCustomFetch } = useFetchBase()

const base = '/api/auth'
const modules = '/users'
const path = base + modules

// request(REQ) Api  --------------------------------
const getUserInfoREQ = path + '/info' // 獲取個人資訊
const getUserDetailsREQ = path + '/details' // 獲取用戶詳情

const getUsersListREQ = path + '/list' // 獲取用戶列表 (含狀態)
const getUserSimpleListREQ = path + '/list/simple' // 獲取用戶列表 (僅基本資訊)
const firstLoginREQ = path + '/onboarding' // 首次登錄修改租戶名
const editUserInfoREQ = path + '/update' // 修改指定用戶
const enabledREQ = path + '/enable/switch' // 停用/啟用定用戶
const deleteUserREQ = path + '/del' // 刪除指定用戶

const getProfileREQ = path + '/profile/get' // 獲取個人檔案
const editProfileREQ = path + '/profile/update' // 修改個人檔案
const getProfileUploadUrlREQ = path + '/profile/upload' // 獲取頭像上傳鏈接

const getTeamListREQ = path + '/list/detail' // 獲取用戶列表(含狀態、邀請中成員)
const searchUsersREQ = path + '/list/q' // 搜索團隊成員 (含邀請中的成員)

const switchDeptREQ = path + '/department/switch' // 轉移使用者的部門
const switchRoleREQ = path + '/role/switch' // 轉移使用者的角色
const switchTenantREQ = path + '/tenant/switch' // 切換租戶
const createTenantREQ = path + '/tenant/create' // 創建租戶

// request(REQ) Function  --------------------------------

/**
 * 獲取個人資訊
 * @param {Object} callback 回調函數
 * @returns 資料(lastTenantLogin)
 */
export const getUserInfoFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getUserInfoREQ, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取用戶詳情
 * @param {Object} callback 回調函數
 * @returns 資料(tenant、user、tenantAgentStatus)
 */
export const getUserDetailsFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getUserDetailsREQ, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取用戶列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 資料
 */
export const getUsersListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getUsersListREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取用戶列表 (可查全部僅基本資訊)
 * @param {Object} type (空值: 不含刪除的用戶、 all: 全部用戶含刪除的用戶)
 * @param {Object} callback 回調函數
 * @returns 資料
 */
export const getUserSimpleListFn = (type, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = type ? apiBaseUrl + getUserSimpleListREQ + `?type=${type}` : apiBaseUrl + getUserSimpleListREQ
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 首次登錄修改租戶名
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const firstLoginFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + firstLoginREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 修改指定用戶
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const editUserInfoFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editUserInfoREQ, params), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 停用/啟用定用戶
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const enabledFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(enabledREQ, params), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除指定用戶
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const deleteUserFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(deleteUserREQ, params), {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 獲取個人檔案
 * @param {Object} callback 回調函數
 * @returns 資料
 */
export const getProfileFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getProfileREQ, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 修改個人檔案
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const editProfileFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + editProfileREQ, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 獲取用戶列表(含狀態、邀請中成員)
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getTeamListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getTeamListREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 搜索團隊成員
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const searchUsersFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(searchUsersREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 轉移使用者的部門
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const switchDeptFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(switchDeptREQ, params), {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 轉移使用者的角色
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const switchRoleFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(switchRoleREQ, params), {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 租戶切換
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const switchTenantFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(switchTenantREQ, params), {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 創建新租戶
 * @param {*} formData 表單提交
 * @param {*} callback 回調函數
 * @returns
 */
export const createTenantFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + createTenantREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 獲取頭像上傳鏈接
 * @param {String} name 檔名
 * @param {Object} callback 回調函數
 * @returns
 */
export const getProfileUploadUrlFn = (name, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getProfileUploadUrlREQ + '?filename=' + name, {
    method: 'GET',
    onResponse: callback
  })
}
