<template>
  <div>
    <div id="searchBar">
      <!-- search Input -->
      <CommonSearchInput
        v-if="isShowSearchInput"
        :model-value="searchInput.condition"
        :disabled="searchInputDisabled"
        @searchFn="searchFn"
        @keyup.enter="searchSubmit"
      />
      <!-- selection Advanced Search -->
      <CommonButton
        :name="expandText"
        :prepend-icon="prependIcon"
        variant="outlined"
        class="me-2"
        style="height:40px;"
        @click="expandFn()"
      />
      <!-- search Btn -->
      <CommonButton
        name="搜尋"
        variant="outlined"
        style="height:40px;"
        @keyup.enter="searchSubmit()"
        @click="searchSubmit()"
      />
    </div>
    <div v-if="isExpand">
      <div v-if="isShowMultipleSelect" id="multipleSelect" class="mt-6 mb-10">
        <div class="d-flex flex-wrap">
          <div v-for="item in multipleSelect" :key="item.name" class="multiplediv">
            <!-- DatePicker -->
            <span v-if="item.type === 'datePicker'" class="createTimeLabel">
              {{ item.name }} :
            </span>
            <CommonDatePicker
              v-if="item.type === 'datePicker'"
              @searchDate="searchDate"
            />

            <CommonMultipleSelectPro
              v-if="item.type === 'select'"
              :key="item.name"
              :label="item.name"
              :multiple-select="item.data"
              :submit-key="item.submitKey"
              :title="item.itemTitle"
              :item-value="item.itemValue"
              @selectFn="selectFn"
            />
            <CommonMultipleSearchInput
              v-if="item.type === 'input'"
              :submit-key="item.submitKey"
              :label="item.name"
              @inputFn="selectFn"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

const props = defineProps({
  selectLabel: {
    type: String,
    default: ''
  },
  /**
   * @param {Array} multipleSelect - 多選選項
   * @param {String} multipleSelect[].name - 選項名稱
   * @param {String} multipleSelect[].type - 選項類型 input 或 select 或date
   * @param {Array} multipleSelect[].data - 選項資料
   * @param {String} multipleSelect[].submitKey - 提交的 key 不能為空, 提交搜索時的key
   * @param {String} multipleSelect[].itemTitle - 選項的 title
   * @param {String} multipleSelect[].itemValue - 選項的 value
   */
  multipleSelect: {
    type: Array,
    default: () => []
  },
  isShowSearchInput: {
    type: Boolean,
    default: false
  },
  isShowMultipleSelect: {
    type: Boolean,
    default: false
  }
})
// 展開進階搜尋
const isExpand = ref(false)
const expandText = ref('進階搜尋')
const prependIcon = ref('fa-solid fa-caret-down')
const expandFn = () => {
  isExpand.value = !isExpand.value

  // 展開時清空搜尋條件並禁用搜尋框
  if (isExpand.value) {
    searchInput.value = { condition: '' }
  }
  searchInputDisabled.value = isExpand.value

  prependIcon.value = isExpand.value ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'
}

// 預設輸入框的值
const searchInput = ref({})
const searchInputDisabled = ref(false)
const searchFn = (v) => {
  searchInput.value = { condition: v }
}

// 進階選項的值
const selectFn = (v) => {
  if (searchInput.value === '') { searchInput.value = {} }
  searchInput.value[v.submitKey] = v.selectedValue
}

// 日期選擇
const searchDate = (val) => {
  searchInput.value.startTime = val.startTime
  searchInput.value.endTime = val.endTime
}

// 提交搜尋
const emit = defineEmits(['searchSubmitFn'])
const searchSubmit = () => {
  emit('searchSubmitFn', {
    searchInput: searchInput.value
  })
}
</script>

<style lang="scss" scoped>
  #searchBar {
    padding:0px 0px;
    display: flex;
    align-items: center;
  }
  .multiplediv{
    display: flex; align-items: center;
    margin-left:20px;
    margin-top :20px;
    padding:0 10px;
    border-radius: 5px;
    div{
        width:300px;
    }
  }
  .createTimeLabel {
    display: inline-block;
    margin-right: 5px;
  }
  :deep(.el-date-editor) {
    width: 100%; height: 40px;
  }
</style>
