<template>
  <div id="blacklist">
    <SetupTitle :name="t('setup.blacklist.title')" />

    <div class="setupMain">
      <div class="tableContent">
        <v-tabs v-model="tab" color="#2F88FF">
          <v-tab value="blacklist" @click="switchTab">
            {{ t('setup.blacklist.currentTab') }}
          </v-tab>
          <v-tab value="history" @click="switchTab">
            {{ t('setup.blacklist.historyTab') }}
          </v-tab>
        </v-tabs>

        <v-card-text class="ps-0 pe-0">
          <v-window v-model="tab">
            <!-- 當前黑名單 -->
            <v-window-item value="blacklist">
              <div id="toolBar">
                <SetupSearchBarPro
                  :is-show-search-input="true"
                  :is-show-multiple-select="true"
                  :multiple-select="multiselect"
                  @searchSubmitFn="searchBlacklist"
                />
                <CommonButtonAdd
                  :name="t('setup.blacklist.addBtn')"
                  @click="showBlacklistDlg()"
                />
              </div>

              <div class="tableStyle">
                <LazySetupBlacklistTable
                  :table-data="blackLists"
                  :current-type="tab"
                  @editBlackList="editBlackListDlg"
                  @deleteBlackList="deleteCheck"
                  @refreshBlackList="refreshBlackList"
                />
              </div>
            </v-window-item>

            <!-- 歷史紀錄 -->
            <v-window-item value="history">
              <div id="toolBar">
                <SetupSearchBarPro
                  :is-show-search-input="true"
                  :is-show-multiple-select="true"
                  :multiple-select="multiselect"
                  @searchSubmitFn="searchBlacklist"
                />
                <CommonButtonAdd
                  :name="t('setup.blacklist.addBtn')"
                  @click="showBlacklistDlg()"
                />
              </div>
              <div class="tableStyle">
                <LazySetupBlacklistTable
                  :table-data="blackLists"
                  :current-type="tab"
                  @editBlackList="editBlackListDlg"
                  @deleteBlackList="deleteCheck"
                  @refreshBlackList="refreshBlackList"
                />
              </div>
            </v-window-item>
          </v-window>
          <SetupBlacklistAddEditDlg
            :is-show="dlgBlackListVisible"
            :dialog-info="blacklistInfo"
            @closeDlg="closeBlackListDlg"
          />
          <CommonDeleteDlg
            v-model:dlgVisible="dlgVisible"
            :data="blacklistInfo.data"
            :sub-title-first="deleteSubFirst"
            :sub-title-second="deleteSubSecond"
            :sub-title-object-name="deleteSubObjectName"
            :content="deleteContent"
            @deleteFn="deleteFn"
          />
        </v-card-text>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  editBlackListFn,
  getBlackListsFn,
  deleteBlackListFn
} from '@/api/admin/blacklist'

import {
  getSimpleChannelsREQFn
} from '@/api/message/channels'

import {
  getUserSimpleListFn
} from '@/api/auth/users'

const { t } = useI18n()

const customerInfo = useCustomerInfo()

const channelMap = new Map()
const getChannelSimpleList = () => {
  const params = {
    type: 'all'
  }
  const onResponse = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      const data = res.data
      data.forEach((item) => {
        if (item.isDeleted !== 0) {
          item.channelName = item.channelName + '(' + t('common.deleted') + ')'
        }
        channelMap.set(item.id, item)
      })
      multiselect.value[1].data = data
    }
  }
  getSimpleChannelsREQFn(params, onResponse)
}

const getUserSimpleList = () => {
  const type = 'all'
  const onResponse = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      const data = res.data
      data.forEach((item) => {
        if (item.isDeleted !== 0) {
          item.username = item.username + '(' + t('common.deleted') + ')'
        }
      })
      multiselect.value[3].data = data
    }
  }
  getUserSimpleListFn(type, onResponse)
}

const multiselect = computed(() => [
  {
    name: t('setup.blacklist.search_createTime'),
    type: 'datePicker',
    submitKey: 'created_at'
  },
  {
    name: t('setup.blacklist.search_channel'),
    submitKey: 'channelId',
    itemTitle: 'channelName',
    itemValue: 'id',
    type: 'select',
    data: []
  },
  {
    name: t('setup.blacklist.search_ip'),
    type: 'input',
    submitKey: 'ip'
  },
  {
    name: t('setup.blacklist.search_users'),
    itemTitle: 'username',
    itemValue: 'agentId',
    type: 'select',
    submitKey: 'creator',
    data: []
  },
  {
    name: t('setup.blacklist.search_customerName'),
    type: 'input',
    submitKey: 'customerName'
  },
  {
    name: t('setup.blacklist.search_reason'),
    type: 'input',
    submitKey: 'reason'
  }
])

const deleteFn = (item) => {
  deleteBlackList(item)
}

const tab = ref('blacklist')

const blackLists = ref([])

onBeforeMount(() => {
  customerInfo.blackProgressLoading = true
  refreshBlackList(1)
  getChannelSimpleList()
  getUserSimpleList()
})

const deleteSubFirst = ref('')
const deleteSubSecond = ref('')
const deleteSubObjectName = ref('')

const deleteContent = ref('')

const searchBlacklist = (keyword) => {
  refreshBlackList(1, keyword.searchInput)
}

// 【黑名單】 -------------------------------------------------
const dlgBlackListVisible = ref(false)
const blacklistInfo = reactive({
  dlg: {
    type: 'add'
  },
  data: {
    id: '',
    ip: '',
    reason: '',
    expiredAt: ''
  }
})

const currentPage = ref(1)
const blacklistInput = ref('')
const historyInput = ref('')

// 切換Tab事件
const switchTab = () => {
  refreshBlackList(1)
  currentPage.value = 1
}

// 顯示 add / edit Dlg
const showBlacklistDlg = () => {
  blacklistInfo.dlg.type = 'add'
  blacklistInfo.data = {
    id: '',
    word: '',
    status: '',
    type: ''
  }
  dlgBlackListVisible.value = true
}

// 關閉 dlg
const closeBlackListDlg = () => {
  dlgBlackListVisible.value = false
  refreshBlackList()
}

// 編輯資料
const editBlackListDlg = (item) => {
  blacklistInfo.dlg.type = 'edit'
  blacklistInfo.data = item
  dlgBlackListVisible.value = true
}

// 編輯黑名單
const editBlackList = (item) => {
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      refreshBlackList()
    }
  }
  const params = {
    blackListId: item.id
  }
  editBlackListFn(params, item, onRes)
}

// 刪除黑名單
const dlgVisible = ref(false)
const deleteCheck = (item) => {
  if (item.ip) {
    deleteSubFirst.value = t('setup.blacklist.deleteDlg_titleFirst')
    deleteSubSecond.value = t('setup.blacklist.deleteDlg_titleSecond')
    deleteSubObjectName.value = item.ip
    deleteContent.value = t('setup.blacklist.deleteDlg_descIP')
  } else {
    deleteSubFirst.value = t('setup.blacklist.deleteDlg_titleFirst')
    deleteSubSecond.value = t('setup.blacklist.deleteDlg_titleSecond')
    deleteSubObjectName.value = item.customerName
    deleteContent.value = t('setup.blacklist.deleteDlg_desCustomer')
  }

  dlgVisible.value = true
  blacklistInfo.data = item
}
const deleteBlackList = (item) => {
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      refreshBlackList()
    }
  }
  const params = {
    blackListId: item.id
  }
  deleteBlackListFn(params, onRes)
  dlgVisible.value = false
}

// 刷新黑名單
const refreshBlackList = async (page, search) => {
  // 判斷當前是哪一個頁簽
  const _isExpired = tab.value === 'history' ? 'true' : 'false'

  // 若有指定頁數則賦值,否則保持原值
  currentPage.value = page || currentPage.value

  let searchContent = {}
  // 若有搜索的值則賦值,否則保持原值
  searchContent = search !== undefined ? search : _isExpired ? blacklistInput.value : historyInput.value

  const params = {
    ...searchContent,
    pageNum: currentPage.value,
    pageSize: 20,
    isExpired: _isExpired
  }

  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      res.data.data.forEach((item) => {
        item.channelName = item.channelId ? channelMap.get(item.channelId).channelName : null
      })
      blackLists.value = res.data

      customerInfo.blackProgressLoading = false
    }
  }

  await getBlackListsFn(params, onRes)
}

provide('provideInfo', {
  refreshBlackList
})
</script>

<style lang="scss" scoped>
@import "assets/styles/pages/setup.scss";
</style>
