import { useRuntimeConfig } from '#app'

const { useCustomFetch } = useFetchBase()

const base = '/api/auth'
const modules = '/trustedDomains'
const path = base + modules

// request(REQ) Api  --------------------------------
const getTrustedDomainListREQ = path + '/list/q' // 搜索信任網站列表
const addTrustedDomainREQ = path + '/add' // 新增信任網站
const editTrustedDomainREQ = path + '/update' // 修改信任網站
const deleteTrustedDomainREQ = path + '/del' // 刪除信任網站

// request(REQ) Function  --------------------------------

/**
 * 搜索信任網站列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getTrustedDomainListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getTrustedDomainListREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 新增信任網站
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const addTrustedDomainFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addTrustedDomainREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 修改信任網站
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const editTrustedDomainFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editTrustedDomainREQ, params), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除信任網站
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const deleteTrustedDomainFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(deleteTrustedDomainREQ, params), {
    method: 'DELETE',
    onResponse: callback
  })
}
